import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";
import { loadingStyles } from "pages/Homepage/async";

const LoggedOutHomepageContainer = Loadable(() =>
  import(/* webpackChunkName: "LoggedOutHomepage" */ "./LoggedOutHomepage")
);

function LoggedOutHomepageLoading(props) {
  return <LoadingOverlay {...props} height={"450rem"} styles={loadingStyles} />;
}

const LoggedOutHomepageAsyncContainer = (props) => {
  return (
    <LoggedOutHomepageContainer
      {...props}
      fallback={<LoggedOutHomepageLoading />}
    />
  );
};

export default LoggedOutHomepageAsyncContainer;
